
jQuery(document).ready(function() {
	jQuery('.page-loader').delay(300).fadeOut('slow');

});

jQuery(document).ready(function() {

	jQuery('#videoModal1').on('shown.bs.modal', function (e) {
		jQuery('#video1').trigger('play');
	})
	jQuery('#videoModal1').on('hide.bs.modal', function (e) {
		jQuery('#video1').trigger('pause');
	})

	jQuery('#blockModal1').on('hide.bs.modal', function (e) {
		jQuery('#modalVideo1').trigger('pause');
	})
	jQuery('#blockModal2').on('hide.bs.modal', function (e) {
		jQuery('#modalVideo2').trigger('pause');
	})
	jQuery('#blockModal6').on('hide.bs.modal', function (e) {
		jQuery('#modalVideo6').trigger('pause');
	})

});

jQuery(document).ready(function() {

	AOS.init({
		offset: 80,
		delay: 0,
		duration: 800,
		easing: 'ease-out-quad',
		once: true,
	});

	// block 1
	jQuery('#blockModal1').on('show.bs.modal', function (e) {
		jQuery('body').addClass('custom-content-modal');
	});
	jQuery('#blockModal1').on('hidden.bs.modal', function (e) {
		jQuery('body').removeClass('custom-content-modal');
	})

	// block 2
	jQuery('#blockModal2').on('show.bs.modal', function (e) {
		jQuery('body').addClass('custom-content-modal');
	});
	jQuery('#blockModal2').on('hidden.bs.modal', function (e) {
		jQuery('body').removeClass('custom-content-modal');
	})

	// block 3
	jQuery('#blockModal3').on('show.bs.modal', function (e) {
		jQuery('body').addClass('custom-content-modal');
	});
	jQuery('#blockModal3').on('hidden.bs.modal', function (e) {
		jQuery('body').removeClass('custom-content-modal');
	})

	// block 4
	jQuery('#blockModal4').on('show.bs.modal', function (e) {
		jQuery('body').addClass('custom-content-modal');
	});
	jQuery('#blockModal4').on('hidden.bs.modal', function (e) {
		jQuery('body').removeClass('custom-content-modal');
	})
});